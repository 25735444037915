import React from "react";
import './Main.css';
import ToDoList from './ToDoList';

const Main = ({toDoList, admin_item_url, setToDoList})=>{

    return(
      
        <div id="main">
          <ToDoList toDoList ={toDoList} admin_item_url={admin_item_url} setToDoList={setToDoList} />
        </div>
    );
}
export default Main;