import React,  { useState,  useRef } from "react";
import axios from 'axios';
import { Button  } from 'react-bootstrap';
 
import './Login.css';

  const Login = ({login_url, setIsLoggedIn, setToDoList, setUserName})=>{
    
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const loginButtonRef = useRef(null);

// Bejelentkezés
const handleLogin = async (login_url) => {
    
    if(email && password)
    {

      try {
        // Bejelentkezési kérés küldése
        const response = await axios.post(login_url, {
        email: email,
        password: password
        });
        
        // Ellenőrizzük, hogy a válasz sikeres volt-e, és tartalmaz-e tokent
        if (response.data.success && response.data.new_token) {
          document.cookie = `todo_token=${response.data.new_token}; expires=Thu, 31 Dec 9999 00:00:00 UTC; path=/`;
          setIsLoggedIn(true);
          setToDoList(JSON.parse(response.data.toDoList));
          setUserName(response.data.userName);
        } else {
          alert('Invalid login credentials');
          setIsLoggedIn(false);
          setToDoList([]);
        }
      } catch (error) {
        console.error('Login failed', error);
        alert('Login failed. Please try again.');
        setIsLoggedIn(false);
        setToDoList([]);
      }
    }
    else{
      alert("Missing datas")
    }
};
  
  

  const handleEmailKeyDown = (e) => {
    if (e.key === "Enter") {
      document.getElementById("password").focus();
    }
  };

  const handlePasswordKeyDown = (e) => {
    if (e.key === "Enter") {
      // Szimuláljuk a login button kattintását
      if (loginButtonRef.current) {
        loginButtonRef.current.click();
      }
    
    }
  };

    return(
      <div className="form-group text-center" id="login_form">
        <div className="login-container">
          <p><input type='text' onChange={(e) => setEmail(e.target.value)} placeholder="email" onKeyDown={handleEmailKeyDown} /></p>
          <p><input type='password' onChange={(e) => setPassword(e.target.value)}  onKeyDown={handlePasswordKeyDown} placeholder="password" /></p>
          <p><Button id="login_button" ref={loginButtonRef} variant="primary" onClick={() => handleLogin(login_url)}>Log In</Button></p>
        </div>
    </div>
    );


}
export default Login; 
