import React, { useState, useEffect  } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from 'axios';

import ToDoItem from './ToDoItem';

import './ToDoList.css';

const ToDoList = ({ toDoList, admin_item_url, setToDoList }) => {
    const [orderBy, setOrderBy] = useState('date')

    // JSON parse, ha string formátumban érkezik
    const parsedToDoList = typeof toDoList === 'string' ? JSON.parse(toDoList) : toDoList;
    // Rendezzük a listát dátum és idő alapján növekvő sorrendbe
    let sortedToDoList = Array.isArray(parsedToDoList)
    ? [...parsedToDoList]
    : [];

     // Rendezés a `switch` segítségével az `orderBy` alapján
     switch (orderBy) {
        case 'date':
            sortedToDoList.sort((a, b) => {
                const dateA = new Date(`${a.date}T${a.time}`);
                const dateB = new Date(`${b.date}T${b.time}`);
                return dateA - dateB;  // Növekvő sorrend
            });
            break;
        
        case 'level':
            sortedToDoList.sort((a, b) => b.level - a.level);  // Csökkenő sorrend
            break;
        
        default:
            console.warn("Invalid orderBy value:", orderBy);
    } 


    //new Item Offcanvas
    const [showNewItemForm, setShowNewItemForm] = useState(false);
    const [showEditItemForm, setShowEditItemForm] = useState(false);
    const [showDeleteItemForm, setShowDeleteItemForm] = useState(false);
    const [editedItem, setEditedItem] = useState(null);
    const [deletedItem, setDeletedItem] = useState(null);
    const [newItemLevel, setNewItemLevel] = useState("0");
    const [editItemLevel, setEditItemLevel] = useState("0");
    const handleNewItemFormClose = () => {setShowNewItemForm(false); setNewItemLevel("0");};
    const handleEditItemFormClose = () => {setShowEditItemForm(false);setEditItemLevel("0"); setEditedItem(null);}
    const handleDeleteItemFormClose = () => {setShowDeleteItemForm(false); setDeletedItem(null);}
    const handleNewItemFormShow = () => setShowNewItemForm(true);
    //const handleEditItemFormShow = () => setShowEditItemForm(true);

    const newItemHandler = async (admin_item_url) =>{
        const title=document.getElementById("newItemTitle").value
        const description=document.getElementById("newItemDescription").value
        const date=document.getElementById("newItemDate").value
        const time=document.getElementById("newItemTime").value
        const level=document.getElementById("newItemLevel").value
        
        if(title && description && date && time &&level)
            {
        
                try {
                    // Bejelentkezési kérés küldése
                    const response = await axios.post(admin_item_url, {
                        action:"new",
                        title:title,
                        description: description,
                        date: date,
                        time:time,
                        level:level
                    },{
                        withCredentials: true // Ezzel az opcióval a cookie-kat is csatolja a kéréshez
                      });
                    
                    // Ellenőrizzük, hogy a válasz sikeres volt-e, és tartalmaz-e tokent
                    if (response.data.success) {
                        console.log(response.data.message)
                        setToDoList(JSON.parse(response.data.toDoList));
                        setShowNewItemForm(false)
                    } else {
                        alert(response.data.message);
                    }
                } catch (error) {
                    alert('Save failed. Please try again: Error'+error);
                }
            }
            else{
                alert("Missing datas")
            }
        

    }

    const editItemHandler = async (admin_item_url) =>{
        const id=editedItem.id
        const title=document.getElementById("editItemTitle").value
        const description=document.getElementById("editItemDescription").value
        const date=document.getElementById("editItemDate").value
        const time=document.getElementById("editItemTime").value
        const level=document.getElementById("editItemLevel").value
        
        if(title && description && date && time &&level)
            {
        
                try {
                    // Bejelentkezési kérés küldése
                    const response = await axios.post(admin_item_url, {
                        action:"edit",
                        id:id,
                        title:title,
                        description: description,
                        date: date,
                        time:time,
                        level:level
                    },{
                        withCredentials: true // Ezzel az opcióval a cookie-kat is csatolja a kéréshez
                      });
                    
                    // Ellenőrizzük, hogy a válasz sikeres volt-e, és tartalmaz-e tokent
                    if (response.data.success) {
                        console.log(response.data.message)
                        setToDoList(JSON.parse(response.data.toDoList));
                        setShowEditItemForm(false)
                    } else {
                        alert(response.data.message);
                    }
                } catch (error) {
                    alert('Save failed. Please try again: Error'+error);
                }
            }
            else{
                alert("Missing datas")
            }
        

    }
    const deleteItemHandler = async (admin_item_url) =>{
        const id=deletedItem.id
        
        if(id)
            {
                try {
                    // Bejelentkezési kérés küldése
                    const response = await axios.post(admin_item_url, {
                        action:"delete",
                        id:id
                    },{
                        withCredentials: true // Ezzel az opcióval a cookie-kat is csatolja a kéréshez
                      });
                    
                    // Ellenőrizzük, hogy a válasz sikeres volt-e, és tartalmaz-e tokent
                    if (response.data.success) {
                        console.log(response.data.message)
                        setToDoList(JSON.parse(response.data.toDoList));
                        setShowDeleteItemForm(false)
                    } else {
                        alert(response.data.message);
                    }
                } catch (error) {
                    alert('Save failed. Please try again: Error'+error);
                }
            }
            else{
                alert("Missing datas")
            }
        

    }

    useEffect(()=>{
        
        if(editedItem!=null) {
            setEditItemLevel(editedItem.level);
            setShowEditItemForm(true)
        }
        else{
            setEditItemLevel(0);
            setShowEditItemForm(false)
        }

    },[editedItem]);

    useEffect(()=>{
        if(deletedItem==null)
        {
            setShowDeleteItemForm(false)
        }
        else{
            setShowDeleteItemForm(true)
        }

    },[deletedItem]);

    return (
        <>
            <div id="dashboard" className="text-center d-flex justify-content-between text-warning">
                <div>Feladatok: {sortedToDoList.length}</div>
                <div><Button variant="warning" onClick={handleNewItemFormShow}>Új feladat</Button> </div>
                <div>
                <Form>
                    <Form.Check
                        checked={orderBy === 'date'}
                        type={'radio'}
                        label={`By Date`}
                        id={`order-date`}
                        name="order"
                        onChange={()=>setOrderBy("date")}
                    />
                    <Form.Check
                        checked={orderBy === 'level'}
                        type={'radio'}
                        label={`By Level`}
                        id={`order-level`}
                        name="order"
                        onChange={()=>setOrderBy("level")}
                    />
                </Form>
                </div>
            </div>
            {sortedToDoList.length > 0 ? (
                sortedToDoList.map((item) => (
                    <ToDoItem key={`todo_item_${item.id}`} item={item} setEditedItem={setEditedItem} setDeletedItem={setDeletedItem} />
                ))
            ) : (
                <p>Nincs elem</p>
            )}
            <Offcanvas show={showNewItemForm} onHide={handleNewItemFormClose}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>New item</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='form-group mb-3'>
                        <input type="text" className="form-control" id="newItemTitle" placeholder='title'/>
                    </div>
                    <div className='form-group mb-3'>
                        <input type="date" className="form-control" id="newItemDate" placeholder='date'/>
                        <input type="time" className="form-control" id="newItemTime" placeholder='time'/>
                    </div>
                    <div className='form-group mb-3'>
                        <textarea className="form-control" id="newItemDescription" placeholder='description'></textarea>
                    </div>
                    <div className='form-group mb-3'>
                        <input type="hidden" className="form-control" id="newItemLevel" min="1" max="5" defaultValue={newItemLevel}/>
                    </div>
                    <div className="level-selector-div d-flex">
                        {[1, 2, 3, 4, 5].map(level => (
                            <div key={`newLevel-${level}`} className={`level-selector level-selector-${level} ${newItemLevel===level ? "checked-selector" : ""}`} onClick={()=>setNewItemLevel(level)}>
                                {level}
                            </div>
                        ))}
                    </div>
                    <p className="text-center"><Button variant="warning" onClick={()=>newItemHandler(admin_item_url)}>Mentés</Button></p>
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas show={showEditItemForm} onHide={handleEditItemFormClose}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>Edit item</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='form-group mb-3'>
                        <input type="text" className="form-control" id="editItemTitle" placeholder='title' defaultValue={editedItem!=null ? editedItem.title : ""} />
                    </div>
                    <div className='form-group mb-3'>
                        <input type="date" className="form-control" id="editItemDate" placeholder='date' defaultValue={editedItem!=null ? editedItem.date : ""}/>
                        <input type="time" className="form-control" id="editItemTime" placeholder='time' defaultValue={editedItem!=null ? editedItem.time : ""}/>
                    </div>
                    <div className='form-group mb-3'>
                        <textarea className="form-control" id="editItemDescription" placeholder='description' defaultValue={editedItem!=null ? editedItem.description : ""}/>
                    </div>
                    <div className='form-group mb-3'>
                        <input type="hidden" className="form-control" id="editItemLevel" min="1" max="5" defaultValue={editItemLevel}/>
                    </div>
                    <div className="level-selector-div d-flex">
                        {[1, 2, 3, 4, 5].map(level => (
                            <div key={`editLevel-${level}`} className={`level-selector level-selector-${level} ${editItemLevel===level ? "checked-selector" : ""}`} onClick={()=>setEditItemLevel(level)}>
                                {level}
                            </div>
                        ))}
                    </div>
                    <p className="text-center"><Button variant="warning" onClick={()=>editItemHandler(admin_item_url)}>Mentés</Button></p>
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas show={showDeleteItemForm} onHide={handleDeleteItemFormClose}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>Delete item</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p>
                        <strong>Valóban törölni kívánja a következő elemet?</strong><br/>
                        Title: {deletedItem!=null ? deletedItem.title : ""}<br/>
                        Date: {deletedItem!=null ? deletedItem.date : ""}<br/>
                        Time: {deletedItem!=null ? deletedItem.time : ""}<br/>
                        Description: {deletedItem!=null ? deletedItem.description : ""}<br/>
                        Level: {deletedItem!=null ? deletedItem.level : ""}
                    </p>
                    <p className="text-center"><Button variant="danger" onClick={()=>deleteItemHandler(admin_item_url)}>Törlés</Button></p>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};


export default ToDoList;