import React from "react";

import './Footer.css';

const Footer = ()=>{
    const date= new Date();
    return(
        <div id="footer">ToDo App {date.getFullYear()} | Grafokom Bt </div>
    );
}

export default Footer;