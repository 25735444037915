import React from "react";
import { Button } from 'react-bootstrap';

import './Header.css';

const Header = ({setIsLoggedIn, setToDoList, userName})=>{

    const handleLogout = () => {
        // Kijelentkezés: A süti törléséhez
        document.cookie = 'todo_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        setIsLoggedIn(false);
        setToDoList([]);
      };

    return(
        <div id="header">
            <img src="/icons/to-do.png" id="logo" alt="logo" />
            <span>{userName}</span>
            <Button id="logout_button" variant="danger" onClick={handleLogout}>Log out</Button>
        </div>
    );
}

export default Header;