import React from 'react';
import './ToDoItem.css';

const ToDoItem = ({ item, setEditedItem, setDeletedItem }) => {
    return (
        <div className="todo-item-card">
            {/* Kártya tartalma, vízszintes elrendezés */}
            <div className="todo-item-content">
                
                {/* Level baloldalon */}
                <div className={`todo-item-level todo-item-level-${item.level}`}>
                    <p>{item.date}<br/>
                    {item.time}</p>
                </div>
                
                {/* Title és Description szövegdobozban */}
                <div className="todo-item-text">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                </div>
                
                {/* Vezérlőgombok jobbra */}
                <div className="todo-item-actions">
                    <button className="btn-edit" onClick={()=>setEditedItem(item)}>Szerkesztés</button>
                    <button className="btn-delete" onClick={()=>setDeletedItem(item)}>Törlés</button>
                </div>
            </div>
        </div>
    );
};

export default ToDoItem;

