import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React, { useState, useEffect } from 'react';

import axios from 'axios';
import config from './config.json'; // JSON fájl importálása

import Login from './components/Login';
import Main from './components/Main';
import Header from './components/Header';
import Footer from './components/Footer';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [toDoList, setToDoList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState("");

  // Ellenőrizzük a todo_token létezését és validitását
  useEffect(() => {
    const checkAuth = async () => {
      const token = document.cookie.split('; ').find(row => row.startsWith('todo_token='));
      //const token="todo_token=todo_token_2024"
      if (token) {
        const tokenValue = token.split('=')[1];

        try {
          const response = await axios.post(config.login_check_url, { todo_token: tokenValue });
          if (response.data.success) {
            console.log("loggedIn")
            setIsLoggedIn(true);
            setToDoList(JSON.parse(response.data.toDoList))
            setUserName(response.data.userName)
          } else {
            console.log("not loggedIn")
            setIsLoggedIn(false);
            setToDoList([]);
          }
        } catch (error) {
          console.error('Error during authentication check', error);
          setIsLoggedIn(false);
          setToDoList([]);
        }
      } else {
        setIsLoggedIn(false);
        setToDoList([]);
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

   

  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {isLoggedIn === null ? (
        <div>Loading...</div>
      ) : isLoggedIn ? (
        <>
          <Header setIsLoggedIn={setIsLoggedIn} setToDoList={setToDoList} userName={userName}/>
          <Main toDoList={toDoList} admin_item_url={config.admin_item_url} setToDoList={setToDoList}/>
          <Footer />
        </>
      ) : (
        <Login login_url={config.login_url} setIsLoggedIn={setIsLoggedIn}  setToDoList={setToDoList} setUserName={setUserName}/>
      )}
    </>
  );
};

export default App;
